import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

function WordCountForm() {
  
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [wordsPurchased, setWordsPurchased] = useState(750);
  const [wordsUsed, setWordsUsed] = useState(0);

  const handleTextChange = (e) => {
    setText(e.target.value);
    const count = e.target.value.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(count);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    if (text.trim()) {
      const newWordCount = text.trim().split(/\s+/).filter(Boolean).length;
      const cost = newWordCount * 0.20; // Calcular costo
  
      if (newWordCount > 0) {
        const newWordsUsed = wordsUsed + newWordCount;
        const newWordsAvailable = wordsAvailable - newWordCount;
  
        if (newWordsAvailable >= 0) {
          setWordsUsed(newWordsUsed);
          setWordsAvailable(newWordsAvailable);
          setWordCount(newWordCount); // Suponiendo que tienes un estado para mantener el recuento de palabras.
          setText(''); // Resetear el área de texto
          // Aquí podrías también manejar el costo total si necesitas almacenarlo
        } else {
          alert('No tienes suficientes palabras disponibles.');
        }
      }
    } else {
      alert('Por favor, introduce algo de texto antes de enviar.');
    }
  };
  

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
    
  //   // Quita espacios al inicio y al final y verifica si el texto no está vacío
  //   if (text.trim()) {
  //     const newWordCount = text.trim().split(/\s+/).length;
  
  //     // Actualiza solo si hay palabras para contar
  //     if (newWordCount > 0) {
  //       const newWordsUsed = wordsUsed + newWordCount;
  //       const newWordsAvailable = wordsAvailable - newWordCount;
  
  //       // Verifica si hay suficientes palabras disponibles
  //       if (newWordsAvailable >= 0) {
  //         setWordsUsed(newWordsUsed);
  //         setWordsAvailable(newWordsAvailable);
  //         // Resetear el texto del formulario
  //         setText('');
  //         setWordCount(0); // Asegúrate de tener este estado si quieres mostrar el contador de palabras
  //       } else {
  //         alert('No tienes suficientes palabras disponibles.');
  //       }
  //     }
  //   } else {
  //     // Maneja el caso de envío de formulario vacío
  //     alert('Por favor, introduce algo de texto antes de enviar.');
  //   }
  // };

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   // Actualizar el estado con las nuevas palabras usadas.
  //   const newWordsUsed = wordsUsed + wordCount;
  //   const newWordsAvailable = wordsAvailable - wordCount;

  //   // Aquí deberías también validar que newWordsAvailable no sea negativo antes de actualizar el estado
  //   if (newWordsAvailable >= 0) {
  //     setWordsUsed(newWordsUsed);
  //     setWordsAvailable(newWordsAvailable);
  //   } else {
  //     // Manejar el caso cuando no hay suficientes palabras disponibles
  //     alert('No tienes suficientes palabras disponibles.');
  //   }

  //   // Si necesitas hacer algo más como enviar los datos a una API o resetear el formulario, lo harías aquí.

  //   // Resetear el texto del formulario (opcional)
  //   setText('');
  // };

  const [wordsAvailable, setWordsAvailable] = useState(750);

  return (
    <Container maxWidth="sm" sx={{ marginTop: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Contador de palabras
      </Typography>
      <Typography variant="subtitle1" gutterBottom align="center">
        ingresa la información para tu nuevo PROMPT
      </Typography>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { margin: 2 },
          '& .MuiButton-root': { margin: 2 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleFormSubmit}
      >
        <TextField
          label="Coloca tus palabras aquí..."
          multiline
          rows={4}
          fullWidth
          value={text}
          onChange={handleTextChange}
          variant="outlined"
          placeholder="Coloca tus palabras aquí..."
        />
        <Typography variant="body1" align="center">
          PALABRAS CONTADAS: {wordCount} (Costo total: ${(wordCount * 0.20).toFixed(2)} USD)
        </Typography>
        <Button variant="contained" color="primary" fullWidth type="submit">
          ENVIAR
        </Button>
      </Box>
      <Box sx={{ backgroundColor: '#f5f5f5', padding: 2, marginTop: 2 }}>
        <Typography variant="body1" gutterBottom>
          PLAN DE PALABRAS COMPRADAS MENSUALMENTE: {wordsPurchased}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Palabras usadas: {wordsUsed}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Palabras disponibles: {wordsAvailable}
        </Typography>
        <Button variant="contained" color="secondary" fullWidth onClick={() => alert('Comprar más palabras')}>
          COMPRAR MÁS PALABRAS
        </Button>
      </Box>
    </Container>
  );
}

export default WordCountForm;
